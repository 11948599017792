import { Action, createReducer, on } from "@ngrx/store";
import { BATH_OPTIONS, BED_OPTIONS } from "./client-dialog-inventory.model";
import { ClientDialogInventoryState } from "../../client-dialog.state";
import { clientDialogInventoryActions, clientDialogInventoryActionsFailed } from "./client-dialog-inventory.action";

export const initialState: ClientDialogInventoryState = {
  inventoryUnits: {
    queryFilterOptions: {
      bedOptions: BED_OPTIONS,
      bathOptions: BATH_OPTIONS
    },
    unitsQueryFilter: {
      unitIdentifier: '',
      baseFilters: {
        isMadeReady: true,
        isNotReady: false,
        isReserved: false,
        minPrice: null,
        maxPrice: null,
        beds: [],
        bath: null,
        vacancyStartDate: '',
        vacancyEndDate: '',
        madeReadyStartDate: '',
        madeReadyEndDate: '',
        buildingPropertyIds: [],
        buildingIds: []
      },
      moreFilters: {
        occupencyStatuses: [],
        leasedStatuses: [],
        economicStatuses: []
      }
    },
    unitsTotalRecords: 0,
    unitsPaginator: {
      page: 1,
      pageSize: 20
    },
    buildingsUnitsQueryFilterBuildingId: -999,
    buildingOptions: [],
    buildingPropertyOptions: [],
    buildings: [],
    buildingUnits: [],
    units: [],
    isPropertiesLoaded: false,
    isUnitsLoaded: false,
    isBuildingUnitsLoaded: false,
  }
};


const reducer = createReducer(
  initialState,
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaUnitsRequested,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isUnitsLoaded: false
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaUnitsLoaded,
    (state, { units, totalRecords, page, pageSize }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isUnitsLoaded: true,
        units: units,
        unitsTotalRecords: totalRecords,
        unitsPaginator: {
          ...state.inventoryUnits.unitsPaginator,
          page: page,
          pageSize: pageSize
        }
      }

    })
  ),
  on(
    clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaUnitsFailed,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isUnitsLoaded: true
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingsRequested,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isPropertiesLoaded: false
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingUnitsRequested,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isBuildingUnitsLoaded: false
      }
    })
  ),
  on(
    clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingsFailed,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isPropertiesLoaded: true
      }
    })
  ),
  on(
    clientDialogInventoryActionsFailed.actionClientDialogInventoryAreaBuildingUnitsFailed,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isBuildingUnitsLoaded: true
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingsLoaded,
    (state, { buildings }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isPropertiesLoaded: true,
        buildings: buildings
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingUnitsLoaded,
    (state, { units }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        isBuildingUnitsLoaded: true,
        buildingUnits: units
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryAreaBuildingOptionsLoaded,
    (state, { buildings }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        buildingOptions: buildings,
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateBuildingPropertyFilterBuildingId,
    (state, { buildingId }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        buildingsUnitsQueryFilterBuildingId: buildingId,
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterSearchByUnit,
    (state, { unitIdentifier }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          unitIdentifier: unitIdentifier
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyCheckbox,
    (state, { checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            isMadeReady: checked
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterNotReadyCheckbox,
    (state, { checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            isNotReady: checked
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterIsReservedCheckbox,
    (state, { checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            isReserved: checked
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsBuildingIdChange,
    (state, { buildingId, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            buildingIds: checked ? [...state.inventoryUnits.unitsQueryFilter.baseFilters.buildingIds, buildingId] : state.inventoryUnits.unitsQueryFilter.baseFilters.buildingIds.filter(x => x != buildingId)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryFilterProptertyIdsToRemoveLoaded,
    (state, { propertyIds }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            buildingPropertyIds: state.inventoryUnits.unitsQueryFilter.baseFilters.buildingPropertyIds.filter(x => !propertyIds.includes(x))
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterBed,
    (state, { bedCount, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            beds: checked ? [...state.inventoryUnits.unitsQueryFilter.baseFilters.beds, bedCount] : state.inventoryUnits.unitsQueryFilter.baseFilters.beds.filter(x => x != bedCount)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterBath,
    (state, { bathCount, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            baths: checked ? [...state.inventoryUnits.unitsQueryFilter.baseFilters.baths, bathCount] : state.inventoryUnits.unitsQueryFilter.baseFilters.baths.filter(x => x != bathCount)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterBathNumber,
    (state, { bath }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            bath: bath
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterBuildingProperty,
    (state, { buildingPropertyId, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            buildingPropertyIds: checked ? [...state.inventoryUnits.unitsQueryFilter.baseFilters.buildingPropertyIds, buildingPropertyId] : state.inventoryUnits.unitsQueryFilter.baseFilters.buildingPropertyIds.filter(x => x != buildingPropertyId)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateBuildingsFromLead,
    (state, { leadBuildingIds }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            buildingIds: leadBuildingIds
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterBuildingPropertyReset,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            buildingPropertyId: initialState.inventoryUnits.unitsQueryFilter.baseFilters.buildingPropertyIds
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterVacancyDate,
    (state, { start, end }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            vacancyStartDate: start,
            vacancyEndDate: end
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyDate,
    (state, { start, end }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            madeReadyStartDate: start,
            madeReadyEndDate: end
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterPrice,
    (state, { minPrice, maxPrice }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            minPrice: minPrice,
            maxPrice: maxPrice
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterOccupencyStatus,
    (state, { occupencyStatusValue, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          moreFilters: {
            ...state.inventoryUnits.unitsQueryFilter.moreFilters,
            occupencyStatuses: checked ? [...state.inventoryUnits.unitsQueryFilter.moreFilters.occupencyStatuses, occupencyStatusValue] : state.inventoryUnits.unitsQueryFilter.moreFilters.occupencyStatuses.filter(x => x != occupencyStatusValue)

          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterLeasedStatus,
    (state, { leasedStatusValue, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          moreFilters: {
            ...state.inventoryUnits.unitsQueryFilter.moreFilters,
            leasedStatuses: checked ? [...state.inventoryUnits.unitsQueryFilter.moreFilters.leasedStatuses, leasedStatusValue] : state.inventoryUnits.unitsQueryFilter.moreFilters.leasedStatuses.filter(x => x != leasedStatusValue)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterEconomicStatus,
    (state, { economicStatusValue, checked }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          moreFilters: {
            ...state.inventoryUnits.unitsQueryFilter.moreFilters,
            economicStatuses: checked ? [...state.inventoryUnits.unitsQueryFilter.moreFilters.economicStatuses, economicStatusValue] : state.inventoryUnits.unitsQueryFilter.moreFilters.economicStatuses.filter(x => x != economicStatusValue)
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryFilterSize,
    (state, { minSize, maxSize }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: {
            ...state.inventoryUnits.unitsQueryFilter.baseFilters,
            minSqft: minSize,
            maxSqft: maxSize
          }
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUpdateUnitsQueryPaginatorPage,
    (state, { page }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsPaginator: {
          ...state.inventoryUnits.unitsPaginator,
          page: page
        }
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsBuildingPropertyLoaded,
    (state, { buildingPropertyOptions }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        buildingPropertyOptions: buildingPropertyOptions
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryBuildingUnitsReset,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        buildingUnits: initialState.inventoryUnits.buildingUnits
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsPaginatorReset,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsPaginator: initialState.inventoryUnits.unitsPaginator
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsFilterReset,
    (state) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        unitsQueryFilter: {
          ...state.inventoryUnits.unitsQueryFilter,
          baseFilters: initialState.inventoryUnits.unitsQueryFilter.baseFilters,
          moreFilters: initialState.inventoryUnits.unitsQueryFilter.moreFilters
        },
        buildingPropertyOptions: []
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsUpdateUnitReserveReleaseResult,
    (state, { result }) => ({
      ...state,
      inventoryUnits: {
        ...state.inventoryUnits,
        units: state.inventoryUnits.units.map(x => x.yardiBuildingPropertyId === result.id ? {
          ...x,
          isReserved: result.isReserved,
          canRelease: result.canRelease,
          reservedBy: result.reservedBy
        } : x),
        buildingUnits: state.inventoryUnits.buildingUnits.map(x => x.yardiBuildingPropertyId === result.id ? {
          ...x,
          isReserved: result.isReserved,
          canRelease: result.canRelease,
          reservedBy: result.reservedBy
        } : x),
      }
    })
  ),
  on(
    clientDialogInventoryActions.actionClientDialogInventoryUnitsStateReset,
    (state) => ({
      ...state,
      inventoryUnits: initialState.inventoryUnits
    })
  )
);

export const clientDialogInventoryReducer = (
  state: ClientDialogInventoryState | undefined,
  action: Action
) => {
  return reducer(state, action);
};

export interface AuthState {
  isAuthenticated: boolean;
  token?: string;
  refreshToken?: string;
  expires?: string;
  profile?: IProfile;
  roles?: string[];
  twoFactorAuthVetifying?: boolean
  isRequiredTwoFactor?: boolean
  isResendingCode?: boolean
}

export interface IApplicationToken {
  userName: string;
  firstName: string;
  lastName: string;
  token: string;
  refreshToken: string;
  expires: string;
  roles: string[];
  userId: number;
  userTimeZone: string;
  intercomUser: string;
  frontVerificationHash: string;
  isRequiredTwoFactor: boolean
}

export interface IProfile {
  firstName?: string;
  lastName?: string;
  profilePic?: string;
  userName?: string;
  userId?: number;
  userTimeZone?: string;
  frontVerificationHash: string;
  intercomUser?: string;
}

export class ApplicationToken implements IApplicationToken {
  userName: string;
  firstName: string;
  lastName: string;
  token: string;
  refreshToken: string;
  expires: string;
  roles: string[];
  userId: number;
  userTimeZone: string;
  intercomUser: string;
  frontVerificationHash: string;
  isRequiredTwoFactor: boolean;
  public constructor(init?: Partial<ApplicationToken>) {
    Object.assign(this, init);
  }
}

export const SESSION_USER_ROLES = {
  Admin: 'Admin',
  User: 'User',
  AccountSettingsManager: 'AccountSettingsManager',
  AutoComTemplateManager: 'AutoComTemplateManager',
  BuildingManager: 'BuildingManager',
  BuildingUser: 'BuildingUser',
  CommunicationTemplateManager: 'CommunicationTemplateManager',
  InsideSalesRepresentative: 'InsideSalesRepresentative',
  RecordingUser: 'RecordingUser',
  Representative: 'Representative',
  SalesAppointmentManager: 'SalesAppointmentManager',
  SalesAppointmentUser: 'SalesAppointmentUser',
  SalesBookingManager: 'SalesBookingManager',
  SalesInventoryUser: 'SalesInventoryUser',
  SalesLeadManager: 'SalesLeadManager',
  SalesLeadUser: 'SalesLeadUser',
  SalesQueueManager: 'SalesQueueManager',
  SalesReportManager: 'SalesReportManager',
  SalesScheduleManager: 'SalesScheduleManager',
  SalesUnifiedInboxManager: 'SalesUnifiedInboxManager',
  UserScheduleManager: 'UserScheduleManager',
  ServiceCoordinator: 'ServiceCoordinator',
  ServiceManager: 'ServiceManager',
  ServiceReceptionist: 'ServiceReceptionist',
  TaskManager: 'TaskManager',
  TaskUser: 'TaskUser',
  UserLiveManager: 'UserLiveManager',
  UserManager: 'UserManager',
  UserProfileUser: 'UserProfileUser',
  VoicemailUser: 'VoicemailUser',
  ApplicationCreator: 'ApplicationCreator',
  ApplicationApprovalsManager: 'ApplicationApprovalsManager',
  ApplicationCancellationManager: 'ApplicationCancellationManager',
  LeaseSignatory: 'LeaseSignatory',
  MerchantAccountManager: 'MerchantAccountManager',
  ApplicationUser: 'ApplicationUser',
  ApplicationDocumentViewer: 'ApplicationDocumentViewer',
  ApplicationDocumentManager: 'ApplicationDocumentManager'
};

import { createAction, props } from "@ngrx/store";
import { IPagination } from "app/shared/models/pagination.model";
import { BuildingProperty, IProperty, InventoryQeurytFilter, IUnit, ReserveReleaseResult, IBuilding } from "./client-dialog-inventory.model";



export const actionClientDialogInventoryAreaUnitsRequested = createAction(
    '[Client Dialog Inventory] Inventory Area Units Requested',
    props<{ queryFilter: InventoryQeurytFilter, paginator: IPagination }>()
);

export const actionClientDialogInventoryAreaUnitsLoaded = createAction(
    '[Client Dialog Inventory] Inventory Area Units Loaded',
    props<{ units: IUnit[], totalRecords: number, page: number, pageSize: number }>()
);

export const actionClientDialogInventoryAreaUnitsFailed = createAction(
    '[Client Dialog Inventory] Inventory Area Units Failed'
);

export const actionClientDialogInventoryAreaBuildingsRequested = createAction(
    '[Client Dialog Inventory] Inventory Area Buildings Requested',
    props<{ buildingId: number }>()
);

export const actionClientDialogInventoryUnitsBuildingIdChange = createAction(
    '[Client Dialog Inventory] Inventory Building Id Change',
    props<{ buildingId: number, checked: boolean }>()
);

export const actionClientDialogInventoryUpdateBuildingsFromLead = createAction(
    '[Client Dialog Inventory] Inventory Update Buildings From Lead',
    props<{ leadBuildingIds: number[] }>()
);

export const actionClientDialogInventoryUpdateBuildingPropertyFilterBuildingId = createAction(
    '[Client Dialog Inventory] Inventory Update Building Property Filter Building Id',
    props<{ buildingId: number }>()
);

export const actionClientDialogInventoryAreaBuildingsLoaded = createAction(
    '[Client Dialog Inventory] Inventory Area Buildings Loaded',
    props<{ buildings: IProperty[] }>()
);

export const actionClientDialogInventoryAreaBuildingsFailed = createAction(
    '[Client Dialog Inventory] Inventory Area Buildings Failed'
);
export const actionClientDialogInventoryAreaBuildingOptionsRequested = createAction(
    '[Client Dialog Inventory] Inventory Area Building Options Requested',
);

export const actionClientDialogInventoryAreaBuildingOptionsLoaded = createAction(
    '[Client Dialog Inventory] Inventory Area Building Options Loaded',
    props<{ buildings: IBuilding[] }>()
);

export const actionClientDialogInventoryAreaBuildingOptionsFailed = createAction(
    '[Client Dialog Inventory] Inventory Area Building Options Failed'
);

export const actionClientDialogInventoryAreaBuildingUnitsRequested = createAction(
    '[Client Dialog Inventory] Inventory Building Property Units Requested',
    props<{ propertyId: number }>()
);

export const actionClientDialogInventoryAreaBuildingUnitsLoaded = createAction(
    '[Client Dialog Inventory] Inventory Area Building Units Loaded',
    props<{ units: IUnit[] }>()
);

export const actionClientDialogInventoryAreaBuildingUnitsFailed = createAction(
    '[Client Dialog Inventory] Inventory Area Building Units Failed'
);

export const actionClientDialogInventoryUpdateUnitsQueryPaginatorPage = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Paginator Page',
    props<{ page: number }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyCheckbox = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Made Ready Checkbox',
    props<{ checked: boolean }>()
);
export const actionClientDialogInventoryUpdateUnitsQueryFilterNotReadyCheckbox = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Not Ready Checkbox',
    props<{ checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterIsReservedCheckbox = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Is Reserved Checkbox',
    props<{ checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterSearchByUnit = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Search By Unit',
    props<{ unitIdentifier: string }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterPrice = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Price',
    props<{ minPrice: number; maxPrice: number }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterSize = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Size',
    props<{ minSize: number; maxSize: number }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterBed = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Bed',
    props<{ bedCount: number; checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterBath = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Bath',
    props<{ bathCount: number; checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterBathNumber = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Bath Number',
    props<{ bath: number }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterVacancyDate = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Vacancy Date',
    props<{ start: string; end: string }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterOccupencyStatus = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Occupency Status',
    props<{ occupencyStatusValue: string, checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterLeasedStatus = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Leased Status',
    props<{ leasedStatusValue: string, checked: boolean }>());

export const actionClientDialogInventoryUpdateUnitsQueryFilterEconomicStatus = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Economic Status',
    props<{ economicStatusValue: string, checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyDate = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Made Ready Date',
    props<{ start: string; end: string }>()
);

export const actionClientDialogInventoryUnitsBuildingPropertyRequest = createAction(
    '[Client Dialog Inventory] Inventory Units Building Property Request',
    props<{ buildingIds: number[] }>()
);

export const actionClientDialogInventoryUnitsBuildingPropertyLoaded = createAction(
    '[Client Dialog Inventory] Inventory Units Building Property Loaded',
    props<{ buildingPropertyOptions: BuildingProperty[] }>()
);

export const actionClientDialogInventoryUnitsBuildingPropertyRequestFailed = createAction(
    '[Client Dialog Inventory] Inventory Units Building Property Request Failed',
);

export const actionClientDialogInventoryBuildingsBuildingPropertyRequest = createAction(
    '[Client Dialog Inventory] Inventory Buildings Building Property Request',
    props<{ buildingIds: number[] }>()
);

export const actionClientDialogInventoryBuildingsBuildingPropertyLoaded = createAction(
    '[Client Dialog Inventory] Inventory Buildings Building Property Loaded',
    props<{ buildingProperties: BuildingProperty[] }>()
);

export const actionClientDialogInventoryBuildingsBuildingPropertyRequestFailed = createAction(
    '[Client Dialog Inventory] Inventory Buildings Building Property Request Failed',
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterBuildingProperty = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Building Property',
    props<{ buildingPropertyId: number, checked: boolean }>()
);

export const actionClientDialogInventoryUpdateUnitsQueryFilterBuildingPropertyReset = createAction(
    '[Client Dialog Inventory] Inventory Update Units Query Filter Building Property Reset',
);

export const actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBed = createAction(
    '[Client Dialog Inventory] Inventory Update Building Units Query Filter Bed',
    props<{ bedCount: number; checked: boolean }>()
);

export const actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBath = createAction(
    '[Client Dialog Inventory] Inventory Update Building Units Query Filter Bath',
    props<{ bathCount: number; checked: boolean }>()
);

export const actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBuilding = createAction(
    '[Client Dialog Inventory] Inventory Update Building Units Query Filter Building',
    props<{ buildingId: number; checked: boolean }>()
);

export const actionClientDialogInventoryUnitsUpdateUnitReserveReleaseResult = createAction(
    '[Client Dialog Inventory] Inventory Update Unit Reserve Release Result',
    props<{ result: ReserveReleaseResult }>()
);

export const actionClientDialogInventoryFilterProptertyIdsToRemoveRequest = createAction(
    '[Client Dialog Inventory] Filter Propterty Ids To Remove Request',
    props<{ buildingId: number }>()
);

export const actionClientDialogInventoryFilterProptertyIdsToRemoveLoaded = createAction(
    '[Client Dialog Inventory] Filter Propterty Ids To Remove Loaded',
    props<{ propertyIds: number[] }>()
);

export const actionClientDialogInventoryFilterProptertyIdsToRemoveFailed = createAction(
    '[Client Dialog Inventory] Filter Propterty Ids To Remove Failed',
);

export const actionClientDialogInventoryUnitsFilterReset = createAction(
    '[Client Dialog Inventory] Inventory Filter Reset',
);

export const actionClientDialogInventoryUnitsPaginatorReset = createAction(
    '[Client Dialog Inventory] Inventory Paginator Reset',
);

export const actionClientDialogInventoryBuildingUnitsReset = createAction(
    '[Client Dialog Inventory] Inventory Building Units Reset',
);

export const actionClientDialogInventoryUnitsStateReset = createAction(
    '[Client Dialog Inventory] Inventory Units State Reset',
);

export const clientDialogInventoryActions = {
    actionClientDialogInventoryAreaUnitsRequested,
    actionClientDialogInventoryAreaUnitsLoaded,
    actionClientDialogInventoryAreaBuildingsRequested,
    actionClientDialogInventoryAreaBuildingsLoaded,
    actionClientDialogInventoryUnitsBuildingIdChange,
    actionClientDialogInventoryUnitsBuildingPropertyRequest,
    actionClientDialogInventoryUnitsBuildingPropertyLoaded,
    actionClientDialogInventoryBuildingsBuildingPropertyRequest,
    actionClientDialogInventoryBuildingsBuildingPropertyLoaded,
    actionClientDialogInventoryUpdateBuildingPropertyFilterBuildingId,
    actionClientDialogInventoryUpdateBuildingsFromLead,
    actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyCheckbox,
    actionClientDialogInventoryUpdateUnitsQueryFilterNotReadyCheckbox,
    actionClientDialogInventoryUpdateUnitsQueryFilterIsReservedCheckbox,
    actionClientDialogInventoryUpdateUnitsQueryFilterSearchByUnit,
    actionClientDialogInventoryAreaBuildingUnitsRequested,
    actionClientDialogInventoryAreaBuildingUnitsLoaded,
    actionClientDialogInventoryAreaBuildingOptionsRequested,
    actionClientDialogInventoryAreaBuildingOptionsLoaded,
    actionClientDialogInventoryUpdateUnitsQueryPaginatorPage,
    actionClientDialogInventoryUpdateUnitsQueryFilterPrice,
    actionClientDialogInventoryUpdateUnitsQueryFilterSize,
    actionClientDialogInventoryUpdateUnitsQueryFilterBed,
    actionClientDialogInventoryUpdateUnitsQueryFilterBath,
    actionClientDialogInventoryUpdateUnitsQueryFilterBathNumber,
    actionClientDialogInventoryUpdateUnitsQueryFilterVacancyDate,
    actionClientDialogInventoryUpdateUnitsQueryFilterMadeReadyDate,
    actionClientDialogInventoryUpdateUnitsQueryFilterBuildingProperty,
    actionClientDialogInventoryUpdateUnitsQueryFilterBuildingPropertyReset,
    actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBed,
    actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBath,
    actionClientDialogInventoryUpdateBuildingUnitsQueryFilterBuilding,
    actionClientDialogInventoryUnitsUpdateUnitReserveReleaseResult,
    actionClientDialogInventoryBuildingUnitsReset,
    actionClientDialogInventoryUnitsFilterReset,
    actionClientDialogInventoryUnitsPaginatorReset,
    actionClientDialogInventoryUnitsStateReset,
    actionClientDialogInventoryUpdateUnitsQueryFilterOccupencyStatus,
    actionClientDialogInventoryUpdateUnitsQueryFilterLeasedStatus,
    actionClientDialogInventoryFilterProptertyIdsToRemoveRequest,
    actionClientDialogInventoryFilterProptertyIdsToRemoveLoaded,
    actionClientDialogInventoryUpdateUnitsQueryFilterEconomicStatus
};

export const clientDialogInventoryActionsFailed = {
    actionClientDialogInventoryAreaUnitsFailed,
    actionClientDialogInventoryAreaBuildingOptionsFailed,
    actionClientDialogInventoryUnitsBuildingPropertyRequestFailed,
    actionClientDialogInventoryBuildingsBuildingPropertyRequestFailed,
    actionClientDialogInventoryAreaBuildingsFailed,
    actionClientDialogInventoryFilterProptertyIdsToRemoveFailed,
    actionClientDialogInventoryAreaBuildingUnitsFailed
};

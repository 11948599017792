export interface Task {
    id: number
    name: string
    date: Date
    type: string
    leadId: number
    leadName: string
    userId: number
    userName: string
    isCompleted: boolean
    reminderType: string
    reminderTimeBefore: number
    removeDate?: string
    completedBy?: string
    completedDate: string
    timeZone: string
}

export interface TaskType {
    id: number
    name: string
    value: string
    icon: string
    color: string
}

export const TASK_TYPES: TaskType[] = [
    {
        id: 1,
        name: 'Call',
        value: 'Call',
        icon: 'phone',
        color: 'var(--sys-primary)'
    },
    {
        id: 2,
        name: 'Text',
        value: 'Text',
        icon: 'textsms',
        color: '#ff8970'
    },
    {
        id: 3,
        name: 'Email',
        value: 'Email',
        icon: 'mail',
        color: '#4354bc'
    },
    {
        id: 4,
        name: 'Follow Up',
        value: 'FollowUp',
        icon: 'contact_phone',
        color: '#F2BB0C'
    },
]

export interface TaskAssignUser {
    id: number
    name: string
    checked?: boolean
}

export interface TaskReminderType {
    id: number
    label: string
    value: string
}

export const TASK_REMINDER_TYPES: TaskReminderType[] = [
    {
        id: 1,
        label: 'Text',
        value: 'Text'
    },
    {
        id: 2,
        label: 'Email',
        value: 'Email'
    },
    {
        id: 3,
        label: 'Text and Email',
        value: 'TextAndEmail'
    }
]

export interface TaskReminderTimeBeforeOption {
    id: number
    value: number
}

export const TASK_REMINDER_TIME_BEFORE_OPTION: TaskReminderTimeBeforeOption[] = [
    {
        id: 1,
        value: 30
    },
    {
        id: 2,
        value: 60
    },
    {
        id: 3,
        value: 90
    },
]

export interface TaskCreateParam {
    taskName: string
    leadId?: number
    tenantId?: number
    taskType: string
    userId: number
    date: string
    time: string
    timeZone: string
    taskReminderType: string
    taskReminderTimeBefore: number
}

export interface TaskUpdateParam {
    id: number
    taskName: string
    taskType: string
    date: string
    time: string
    timeZone: string
    taskReminderType: string
    taskReminderTimeBefore: number
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { InitRentalRequest, RentalApplicationSetting, CreditCheckForm, RentalApplicationInfoUpdateForm } from '../../client-dialog-model';

@Injectable()
export class ClientDialogApplicationService {

  constructor(
    private http: HttpClient
  ) {

  }

  cancelApplication(rentalApplicationId: number, reasonType: string, reason: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/cancelRentalApplication`, {
      id: rentalApplicationId,
      reasonType,
      reason
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addNewApplication(rentalApplicationSetting: RentalApplicationSetting) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/addNewRentalApplication`, rentalApplicationSetting)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getClientRentalApplications(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplications?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getClientRentalApplicationForm(formId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationForm?formId=${formId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  startRentalApplication(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/startRentalApplication?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationTemplateForms(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationTemplateForms?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestTemplateForms(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestTemplateForms?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalApplicationForm(rentalApplicationId: number, rentalApplicationFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalApplicationForm?rentalApplicationId=${rentalApplicationId}&rentalApplicationFormId=${rentalApplicationFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalRequestForm(rentalRequestId: number, rentalRequestFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalRequestForm?rentalRequestId=${rentalRequestId}&rentalRequestFormId=${rentalRequestFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalApplicationCertnVerificationReport(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalApplicationCertnVerificationReport?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalApplicationPlaidVerificationReport(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalApplicationPlaidVerificationReport?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalApplicationFormFile(rentalApplicationId: number, rentalApplicationFormFileId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalApplicationFormFile?rentalApplicationId=${rentalApplicationId}&rentalApplicationFormFileId=${rentalApplicationFormFileId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  removeRentalRequestFormFile(rentalRequestId: number, rentalRequestFormFileId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalRequestFormFile?rentalRequestId=${rentalRequestId}&rentalRequestFormFileId=${rentalRequestFormFileId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationFormViewerDocument(rentalApplicationFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationFormViewerDocument?rentalApplicationFormId=${rentalApplicationFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationUploadedFileViewerDocument(rentalApplicationFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationUploadedFileViewerDocument?rentalApplicationFormId=${rentalApplicationFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestUploadedFileViewerDocument(rentalRequestFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestUploadedFileViewerDocument?rentalRequestFormId=${rentalRequestFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestFormViewerDocument(rentalRequestFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestFormViewerDocument?rentalRequestFormId=${rentalRequestFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationCertnReportViewerDocument(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationCertnReportViewerDocument?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationPlaidReportViewerDocument(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationPlaidReportViewerDocument?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationPlaidIdentityReportViewerDocument(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationPlaidIdentityReportViewerDocument?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationCertnIdentityReportViewerDocument(reportId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationCertnIdentityReportViewerDocument?reportId=${reportId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationFormEditorDocument(rentalApplicationFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationFormEditorDocument?rentalApplicationFormId=${rentalApplicationFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestFormEditorDocument(rentalRequestFormId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestFormEditorDocument?rentalRequestFormId=${rentalRequestFormId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationFormList(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationFormList?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestFormList(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestFormList?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplication(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplication?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalApplicationFormFileList(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalApplicationFormFileList?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestFormFileList(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestFormFileList?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getClientRentalRequests(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequests?leadId=${leadId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getClientRentalRequest(rentalRequestId: number, leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequest?rentalRequestId=${rentalRequestId}&leadId=${leadId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  initNewRentalRequest(initRentalRequest: InitRentalRequest) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/initNewRentalRequest`, initRentalRequest)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateRentalLeasingDates(rentalRequestId: number, moveInDate: Date, leaseStartDate: Date, leaseEndDate: Date) {
    let payload = {
      rentalRequestId,
      moveInDate,
      leaseStartDate,
      leaseEndDate
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/updateRentalLeasingDates`, payload)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalItems(unitId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalItems?unitId=${unitId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getLeasingRepresentativesByBuilding(buildingId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/GetLeasingRepresentativesByBuilding?buildingId=${buildingId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  cancelRentalRequest(rentalRequestId: number, reasonType: string, reason: string, leadId: number) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/cancelRentalRequest`, {
      id: rentalRequestId,
      reasonType,
      reason,
      leadId
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  saveRentalApplicationFormData(formId: number, data: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/saveRentalApplicationFormData`, {
      formId: formId,
      data: data
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  saveRentalRequestFormData(formId: number, data: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/saveRentalRequestFormData`, {
      formId: formId,
      data: data
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  resendApplicantEmail(rentalApplicationId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/resendApplicantEmail?rentalApplicationId=${rentalApplicationId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  approveAndSignForm(formId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/approveAndSignForm?formId=${formId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  uploadRentalApplicationManualFile(rentalApplicationId: number, fileName: string, file: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/uploadRentalApplicationManualFile`, {
      rentalApplicationId: rentalApplicationId,
      fileName: fileName,
      file: file
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  uploadRentalRequestManualFile(rentalRequestId: number, fileName: string, file: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/uploadRentalRequestManualFile`, {
      rentalRequestId: rentalRequestId,
      fileName: fileName,
      file: file
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  readyForReview(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/readyForReview?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  rollbackStatus(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/rollbackStatus?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  startRentalRequest(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/startRentalRequest?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  approveRentalRequest(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/approveRentalRequest?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  signRentalRequest(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/signRentalRequest?rentalRequestId=${rentalRequestId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  addNewRentalApplicationForms(rentalApplicationId: number, formIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/addNewRentalApplicationForms`, {
      rentalApplicationId,
      formIds,
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addNewRentalRequestForms(rentalRequestId: number, formIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/addNewRentalRequestForms`, {
      rentalRequestId,
      formIds,
    })
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addNewRentalApplicationCreditCheck(rentalApplicationId: number, creditCheckForm: CreditCheckForm) {
    let payload = {
      rentalApplicationId,
      ...creditCheckForm
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/addNewRentalApplicationCreditCheck`, payload)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  sendRentalApplicationFileUploadLink(rentalApplicationId: number, note: string) {
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/sendApplicantFileUploadLink`,
      {
        rentalApplicationId: rentalApplicationId,
        note: note
      }
    )
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRentalRequestActivities(rentalRequestId: number, pageNo: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalRequestActivities?rentalRequestId=${rentalRequestId}&pageNo=${pageNo}&pageSize=${pageSize}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }


  rentalApplicationInfoUpdate(rentalApplicationId: number, updateInfoForm: RentalApplicationInfoUpdateForm) {
    let payload = {
      rentalApplicationId,
      ...updateInfoForm
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/rentalApplicationInfoUpdate`, payload)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  addRentalItemsToRentalRequest(rentalRequestId: number, rentalItemIds: number[]) {
    let payload = {
      rentalRequestId: rentalRequestId,
      rentalItemIds: rentalItemIds
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/addRentalItemsToRentalRequest`, payload)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  removeRentalItemFromRentalRequest(rentalRequestId: number, rentalItemId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/removeRentalItemFromRentalRequest?rentalRequestId=${rentalRequestId}&rentalItemId=${rentalItemId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalItemsOptionsForRentalRequest(rentalRequestId: number, type: string, page: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalItemsOptionsForRentalRequest?rentalRequestId=${rentalRequestId}&type=${type}&page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalItemsForRentalRequest(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalItemsForRentalRequest?rentalRequestId=${rentalRequestId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalItemTypeOptionsByRentalRequest(rentalRequestId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getRentalItemTypeOptionsByRentalRequest?rentalRequestId=${rentalRequestId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  leasingDocSignatureReset(formId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/leasingDocSignatureReset?formId=${formId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalApplicationLeadInfoByLeadId(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getLeadInfoByLeadId?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRentalApplicationUnits(inventoryId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail-application/getReadyUnitsFromSameBuildingForTheUnit?inventoryId=${inventoryId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  updateRentalRequestUnit(rentalRequestId: number, unitId: number) {
    let payload = {
      rentalRequestId,
      unitId,
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail-application/updateRentalRequestUnit`, payload)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }
}

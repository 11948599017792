import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import { State } from 'app/features/client-dialog/client-dialog.state';

import { Observable, Subject, takeUntil } from 'rxjs';
import { actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested } from '../../client-dialog-rental-application.action';
import { environment } from 'environments/environment';
import { Editor } from 'tinymce';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-send-link-confirm-dialog',
  templateUrl: './client-dialog-rental-application-send-link-confirm-dialog.component.html',
  styleUrls: ['./client-dialog-rental-application-send-link-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationSendLinkConfirmDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    @Inject('dialogData') private dialogData: any,
    private dialogRef: MatDialogRef<ClientDialogRentalApplicationSendLinkConfirmDialogComponent>) {
    this.placeholder = dialogData.placeholder;
    this.notificationText = dialogData.notificationText;
    this.retntalApplicationId = dialogData.retntalApplicationId;
    this.resetValidationMessageAction = dialogData.resetValidationMessageAction;
    this.isActionActive$ = dialogData.isActionActive;
    this.actionValidationMessages$ = dialogData.actionValidationMessages;
    this.editorSettings = Object.assign(this.editorSettings, { placeholder: dialogData.placeholder })
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  placeholder: string = '';
  notificationText: string;
  retntalApplicationId: number;
  isActionActive$: Observable<boolean>;
  actionValidationMessages$: Observable<ClientDialogRentalApplicationSendLinkConfirmDialogComponent>;

  resetValidationMessageAction: Action;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Send Upload Link',
    flat: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    customClass: 'primary-contrast-80',
    spinnerCustomClass: 'spinner-primary-contrast-30'
  };

  note: UntypedFormControl = new UntypedFormControl('', [Validators.required])

  tinyMceApiKey = environment.tinyMce.tinyMceApiKey;
  editor: Editor;
  editorSettings = {
    branding: false,
    placeholder: this.placeholder,
    toolbar: '',
    plugins: '',
    menubar: '',
    file_picker_types: 'file image media',
    browser_spellcheck: true,
    external_plugins: {},
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: [],
    setup: (editor: Editor) => {
      this.editor = editor;
    }
  };

  ngOnInit(): void {

    this.isActionActive$.pipe(takeUntil(this.unsubscribe$)).subscribe(isActive => {
      this.spinnerButtonOptions.active = isActive;
      this.cdr.markForCheck();
    });
  }

  onAction() {
    if (this.spinnerButtonOptions.active == false && !this.note.invalid) {
      this.store.dispatch(actionClientDialogApplicationRentalApplicationFileUploadLinkSendRequested(
        {
          rentalApplicationId: this.retntalApplicationId,
          note: this.note.value
        }
      ));
    }
  }

  ngOnDestroy(): void {
    if (this.resetValidationMessageAction != undefined) {
      this.store.dispatch(this.resetValidationMessageAction);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

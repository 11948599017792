<div class="client-dialog-inventory-component">
    <div class="desktop-container">
        <div class="main-container dq-box-shadow">
            <div class="hero">
                <div class="hero-content">
                    <h3>Inventory</h3>
                    <!-- <span class="small-title">{{area.name}} Inventory</span> -->
                    <div class="search-container"></div>
                </div>
            </div>
            <div class="buildings-container">
                <div class="filter-div">
                    <div class="search-div search-input">
                        <mat-icon class="search-icon">search</mat-icon>
                        <input class="search-input" type="text"
                            placeholder="Search By Unit" [formControl]="unitsSearchControl">
                        <mat-checkbox [checked]="isMadeReady$ | async" (change)="isUnitsMadeReady($event)"
                            class="made-ready-box">
                            Made Ready
                        </mat-checkbox>
                        <mat-checkbox [checked]="isReserved$ | async" (change)="isReserved($event)"
                            class="made-ready-box">
                            Is Reserved
                        </mat-checkbox>
                        <mat-checkbox [checked]="isNotReady$ | async" (change)="isUnitsNotReady($event)"
                            class="made-ready-box">
                            Not Ready
                        </mat-checkbox>
                    </div>
                    <div style="display: flex; justify-content: center;">
                        <!--Price Filter-->
                        <button mat-stroked-button matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small"
                            [matBadgeHidden]="priceMinValueField == '0' && priceMaxValueField == 'Unlimited'"
                            [matMenuTriggerFor]="priceMenu" (menuOpened)="onPriceSliderPopupMenuOpened()">
                            <span class="d-xl-inline">
                                Price
                            </span>

                        </button>
                        <mat-menu #priceMenu="matMenu" yPosition="below"
                            class="lead-profile-inventory-filter-toolbar-mat-menu-content filter-toolbar-price-mat-menu">
                            <ng-template matMenuContent>
                                <div (click)="$event.stopPropagation()">
                                    <span class="row no-gutters priceInputRow">
                                        <span class="col-sm-6">
                                            <label>MinPrice</label>
                                            <mat-form-field>
                                                <input type="text" #priceMinFilterBarInput placeholder="Min price"
                                                    type="text" cdkNumberDotComma aria-label="Number" matInput
                                                    #minPriceField [(ngModel)]="priceMinValueField"
                                                    (focusout)="onPriceFocusOut()">
                                            </mat-form-field>
                                        </span>
                                        <span class="col-sm-6">
                                            <label>MaxPrice</label>
                                            <mat-form-field>
                                                <input type="text" #priceMaxFilterBarInput placeholder="Max price"
                                                    type="text" cdkNumberDotComma aria-label="Number" matInput
                                                    #maxPriceField [(ngModel)]="priceMaxValueField"
                                                    (focusout)="onPriceFocusOut()" (focusin)="onPriceFocusIn()">
                                            </mat-form-field>
                                        </span>
                                    </span>
                                    <dq-manual-slider #priceManualSlider [options]="priceOptions"
                                        [minValue]="priceMinSliderValue" [maxValue]="priceMaxSliderValue"
                                        (onUserSliderChange)="onUserChangePriceSlider($event)"
                                        (onUserSliderChangeEnd)="onUserChangePriceSliderEnd($event)">
                                    </dq-manual-slider>
                                </div>
                            </ng-template>
                        </mat-menu>
                        <!--Sqft Filter-->
                        <!-- <button matTooltipPosition="left" matTooltipClass="link-tip" class="m-2 mat-menu-toggle"
                        mat-stroked-button [matMenuTriggerFor]="sizeMenu"
                        (menuOpened)="onSizeSliderPopupMenuOpened()">
                        <span class="d-xl-inline">
                            Size
                        </span>
                        
                    </button>
                    <mat-menu #sizeMenu="matMenu" yPosition="below"
                        class="lead-profile-inventory-filter-toolbar-mat-menu-content filter-toolbar-price-mat-menu">
                        <ng-template matMenuContent>
                            <div (click)="$event.stopPropagation()">
                                <span class="row no-gutters priceInputRow">
                                    <span class="col-sm-6">
                                        <label>MinSize</label>
                                        <mat-form-field>
                                            <input type="text" #sizeMinFilterBarInput placeholder="Min size"
                                                type="text" cdkNumberDotComma aria-label="Number" matInput
                                                #minsizeField [(ngModel)]="sizeMinValueField"
                                                (focusout)="onSizeFocusOut()">
                                        </mat-form-field>
                                    </span>
                                    <span class="col-sm-6">
                                        <label>MaxSize</label>
                                        <mat-form-field>
                                            <input type="text" #sizeMaxFilterBarInput placeholder="Max size"
                                                type="text" cdkNumberDotComma aria-label="Number" matInput
                                                #maxsizeField [(ngModel)]="sizeMaxValueField"
                                                (focusin)="onSizeFocusIn()" (focusout)="onSizeFocusOut()">
                                        </mat-form-field>
                                    </span>
                                </span>
                                <dq-manual-slider #sizeManualSlider [options]="sizeOptions"
                                    [minValue]="sizeMinSliderValue" [maxValue]="sizeMaxSliderValue"
                                    (onUserSliderChange)="onUserChangeSizeSlider($event)"
                                    (onUserSliderChangeEnd)="onUserChangeSizeSliderEnd($event)">
                                </dq-manual-slider>
                            </div>
                        </ng-template>
                    </mat-menu> -->
                        <!--Bed Filter-->
                        <button mat-stroked-button matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small" [matBadgeHidden]="unitQueryFilters.baseFilters.beds.length == 0"
                            [matMenuTriggerFor]="bed">
                            <span class="d-xl-inline">
                                Beds
                            </span>

                        </button>
                        <mat-menu mat-stroked-button class="category-filter-menu" #bed="matMenu">
                            <div class="d-flex flex-wrap flex-column" (click)="$event.stopPropagation()"
                                *ngFor="let bedOption of (bedOptions$ | async)">
                                <mat-checkbox *ngIf="bedOption" class="col-12 py-2 px-3" [value]="bedOption.count"
                                    [checked]="isBedFilterChecked(bedOption.count)"
                                    (change)="unitsBedFilterChange($event)">
                                    {{bedOption.label}}
                                </mat-checkbox>
                            </div>
                        </mat-menu>
                        <!--Bath Filter-->
                        <button mat-stroked-button matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small" [matBadgeHidden]="unitQueryFilters.baseFilters.bath == 0"
                            [matMenuTriggerFor]="bath">
                            <span class="d-xl-inline">
                                Baths
                            </span>

                        </button>
                        <mat-menu class="category-filter-menu" #bath="matMenu">
                            <mat-radio-group [formControl]="bathFormControl" class="category-radio-group">
                                <mat-radio-button *ngFor="let bathOption of (bathOptions$ | async)"
                                    class="category-radio-button" (click)="$event.stopPropagation()"
                                    [value]="bathOption.count">
                                    {{bathOption.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </mat-menu>
                        <!--Made Ready Date Filter-->
                        <button mat-stroked-button matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small" [matBadgeHidden]="!madeReadyRange.get('end').value"
                            (click)="madeReadyPicker.open()">
                            <mat-icon class="filter-icon" svgIcon="calendar-check"></mat-icon>
                            <span class="d-xl-inline">
                                <ng-container
                                    *ngIf="madeReadyRange.get('start').value && madeReadyRange.get('end').value; else noMadeReadyRange">
                                    {{madeReadyRange.get('start').value | date: 'MMM d'}} to
                                    {{madeReadyRange.get('end').value
                                    | date:
                                    'MMM d'}}
                                </ng-container>
                                <ng-template #noMadeReadyRange>
                                    Made Ready Date
                                </ng-template>
                            </span>

                            <mat-date-range-input [formGroup]="madeReadyRange" [rangePicker]="madeReadyPicker"
                                class="rang-input">
                                <input matStartDate formControlName="start" placeholder="Start date">
                                <input matEndDate formControlName="end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-date-range-picker #madeReadyPicker></mat-date-range-picker>
                        </button>
                        <!--Vacancy Date Filter-->
                        <button mat-stroked-button matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small" [matBadgeHidden]="!vacancyRange.get('end').value"
                            (click)="vacancyPicker.open()">
                            <mat-icon class="filter-icon" svgIcon="calendar-check"></mat-icon>
                            <span class="d-xl-inline">
                                <ng-container
                                    *ngIf="vacancyRange.get('start').value && vacancyRange.get('end').value; else noVacancyRange">
                                    {{vacancyRange.get('start').value | date: 'MMM d'}} to
                                    {{vacancyRange.get('end').value
                                    | date:
                                    'MMM d'}}
                                </ng-container>
                                <ng-template #noVacancyRange>
                                    Vacancy Date
                                </ng-template>
                            </span>

                            <mat-date-range-input [formGroup]="vacancyRange" [rangePicker]="vacancyPicker"
                                class="rang-input">
                                <input matStartDate formControlName="start" placeholder="Start date">
                                <input matEndDate formControlName="end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-date-range-picker #vacancyPicker></mat-date-range-picker>
                        </button>
                    </div>
                    <div>
                        <!--Building Filter-->
                        <button mat-stroked-button mat-stroked-button matTooltipPosition="left"
                            matTooltipClass="link-tip" class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*"
                            matBadgeColor="accent" matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.baseFilters.buildingIds.length == 0"
                            [matMenuTriggerFor]="building">
                            <mat-icon class="filter-icon" svgIcon="building"></mat-icon>
                            <span class="d-xl-inline">
                                Communities
                            </span>

                        </button>
                        <mat-menu class="inventory-filter-menu" #building="matMenu">
                            <div class="d-flex flex-wrap flex-column" (click)="$event.stopPropagation()">
                                <div class="search-div search-input">
                                    <mat-icon class="search-icon">search</mat-icon>
                                    <input class="search-input" type="text"
                                        placeholder="Search" [formControl]="communitySearchControl">
                                </div>
                                <div *ngFor="let building of (buildingSearchOptions$ | async)">
                                    <mat-checkbox [checked]="isCommunityChecked(building.id)"
                                        (change)="communitiesChecked($event)" class="category-radio-button"
                                        [value]="building.id">
                                        {{building.name}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </mat-menu>
                        <!--Building Property Filter-->
                        <button mat-stroked-button mat-stroked-button matTooltipPosition="left"
                            matTooltipClass="link-tip" class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*"
                            matBadgeColor="accent" matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.baseFilters.buildingPropertyIds.length == 0"
                            [matMenuTriggerFor]="buildingProperty"
                            [disabled]="(buildingPropertyOptions$ | async).length == 0">
                            <mat-icon class="filter-icon" svgIcon="building"></mat-icon>
                            <span class="d-xl-inline">
                                Properties
                            </span>

                        </button>
                        <mat-menu class="inventory-filter-menu" #buildingProperty="matMenu">
                            <div class="d-flex flex-wrap flex-column" (click)="$event.stopPropagation()">
                                <div *ngFor="let buildingProperty of (buildingPropertyOptions$ | async)">
                                    <mat-checkbox class="category-radio-button" (change)="propertiesChecked($event)"
                                        [checked]="isPropertyChecked(buildingProperty.buildingPropertyId)"
                                        [value]="buildingProperty.buildingPropertyId">
                                        {{buildingProperty.name}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </mat-menu>

                        <button mat-stroked-button mat-stroked-button class="m-2 mat-menu-toggle" mat-stroked-button
                            [matMenuTriggerFor]="moreFiltersMenu" matTooltipPosition="left" matTooltipClass="link-tip"
                            matBadge="*" matBadgeColor="accent" matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.moreFilters.economicStatuses.length == 0 && unitQueryFilters.moreFilters.leasedStatuses.length == 0 && unitQueryFilters.moreFilters.occupencyStatuses.length == 0">
                            <mat-icon class="filter-icon" svgIcon="plus"></mat-icon>
                            <span class="d-xl-inline">
                                Filters
                            </span>

                        </button>
                        <mat-menu class="inventory-filter-menu" #moreFiltersMenu="matMenu">
                            <div style="padding:10px;">
                                <button matTooltipPosition="left" matTooltipClass="link-tip" matBadge="*"
                                    matBadgeColor="accent" matBadgeSize="small"
                                    [matBadgeHidden]="unitQueryFilters.moreFilters.occupencyStatuses.length == 0"
                                    mat-menu-item [matMenuTriggerFor]="occupencyStatusFilterMenu">
                                    Occupency Status
                                </button>

                                <button matTooltipPosition="left" matTooltipClass="link-tip" matBadge="*"
                                    matBadgeColor="accent" matBadgeSize="small"
                                    [matBadgeHidden]="unitQueryFilters.moreFilters.leasedStatuses.length == 0"
                                    mat-menu-item [matMenuTriggerFor]="leasedStatusFilterMenu">
                                    Leased Status
                                </button>

                                <button matTooltipPosition="left" matTooltipClass="link-tip" matBadge="*"
                                    matBadgeColor="accent" matBadgeSize="small"
                                    [matBadgeHidden]="unitQueryFilters.moreFilters.economicStatuses.length == 0"
                                    mat-menu-item [matMenuTriggerFor]="economicStatusFilterMenu">
                                    Unit Economic Status
                                </button>
                            </div>
                        </mat-menu>

                        <mat-menu #occupencyStatusFilterMenu="matMenu" matTooltipPosition="left"
                            matTooltipClass="link-tip" class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*"
                            matBadgeColor="accent" matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.moreFilters.occupencyStatuses.length == 0">
                            <div *ngFor="let occupencyStatusOption of occupencyStatusOptions">
                                <mat-checkbox class="example-radio-button"
                                    (change)="unitsOccupencyStatusFilterChange($event)"
                                    [checked]="isOccupencyStatusChecked(occupencyStatusOption.value)"
                                    [value]="occupencyStatusOption.value">
                                    {{occupencyStatusOption.label}}
                                </mat-checkbox>
                            </div>
                        </mat-menu>

                        <mat-menu #leasedStatusFilterMenu="matMenu" matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*" matBadgeColor="accent"
                            matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.moreFilters.leasedStatuses.length == 0">
                            <div *ngFor="let leasedStatusOption of leasedStatusOptions">
                                <mat-checkbox class="example-radio-button"
                                    (change)="unitsLeasedStatusFilterChange($event)"
                                    [checked]="isLeasedStatusChecked(leasedStatusOption.value)"
                                    [value]="leasedStatusOption.value">
                                    {{leasedStatusOption.label}}
                                </mat-checkbox>
                            </div>
                        </mat-menu>

                        <mat-menu #economicStatusFilterMenu="matMenu" matTooltipPosition="left"
                            matTooltipClass="link-tip" class="m-2 mat-menu-toggle" mat-stroked-button matBadge="*"
                            matBadgeColor="accent" matBadgeSize="small"
                            [matBadgeHidden]="unitQueryFilters.moreFilters.economicStatuses.length == 0">
                            <div *ngFor="let economicStatusOption of unitEconomicStatusOptions">
                                <mat-checkbox class="example-radio-button"
                                    (change)="unitsEconomicStatusFilterChange($event)"
                                    [checked]="isEconomicStatusChecked(economicStatusOption.value)"
                                    [value]="economicStatusOption.value">
                                    {{economicStatusOption.label}}
                                </mat-checkbox>
                            </div>
                        </mat-menu>

                        <button mat-stroked-button [disabled]="isBasicFiltersApplied && isMoreFiltersApplied"
                            matTooltipPosition="left" matTooltipClass="link-tip"
                            class="m-2 mat-menu-toggle error-stroked-contrast-40" mat-stroked-button
                            (click)="unitsFilterReset()">
                            <mat-icon class="filter-icon" svgIcon="undo"></mat-icon>
                            <span class="d-xl-inline">
                                Reset
                            </span>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="(isUnitsLoaded$ | async); else unitsLoading">
                    <div class="unit-table-header">
                        <div class="row">
                            <div class="col-xl-4 col-l-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <span class="property-title">Property</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <span>Beds/Baths</span>
                                    </div>
                                    <div class="col-sm-1">
                                        <span>#Unit</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-l-12">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <span>Price</span>
                                    </div>
                                    <div class="col-sm-1">
                                        <span>Special Price</span>
                                    </div>
                                    <div class="col-sm-1">
                                        <span>Sqft</span>
                                    </div>
                                    <div class="col-sm-2">
                                        <span>Made Ready Date</span>
                                    </div>
                                    <div class="col-sm-2">
                                        <span>Vacancy Date</span>
                                    </div>
                                    <div class="col-sm-3">
                                        <span>Amenities</span>
                                    </div>
                                    <div class="col-sm-1">
                                        <span><mat-icon>info</mat-icon></span>
                                    </div>
                                    <div class="col-sm-1">
                                        <div class="reserve-header"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container
                        *ngFor="let unit of (units$ | async) | paginate: { itemsPerPage: (unitsPaginator$ | async).pageSize, currentPage: (unitsPaginator$ | async).page, totalItems:  (unitsTotalRecords$ | async) }; let i = index">
                        <div class="unit-row bottom-dvider">
                            <!-- <div class="unit-row" [ngClass]="{'bottom-dvider': i < (units$ | async).length-1}"> -->
                            <div class="row">
                                <div class="col-xl-4 col-l-12">
                                    <div class="row unit-detail-row unit-row">
                                        <div class="col-sm-6">
                                            <div class="unit-detail-info">
                                                <div>
                                                    <p class="unit-building-name">{{unit.buildingName}}
                                                    </p>
                                                    <p class="unit-building-address">
                                                        {{unit.buildingAddress}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="unit-detail-info">
                                                {{unit.beds}} BD | {{unit.baths}} BA
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="unit-detail-info">
                                                {{unit.yardiUnitIdentifier}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-8 col-l-12">
                                    <div class="row unit-detail-row unit-row">
                                        <div class="col-sm-1">
                                            <div class="unit-detail-info">
                                                <ng-container *ngIf="unit.price > 0; else priceNA">
                                                    ${{unit.price | number: '2.'}}
                                                </ng-container>
                                                <ng-template #priceNA>
                                                    Not Available
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="unit-detail-info">
                                                <ng-container *ngIf="unit.specialPrice > 0; else priceNA">
                                                    ${{unit.specialPrice | number: '2.'}}
                                                </ng-container>
                                                <ng-template #priceNA>
                                                    Not Available
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="unit-detail-info">
                                                <ng-container *ngIf="unit.unitSquareFeet > 0; else sqftNA">
                                                    {{unit.unitSquareFeet | number: '2.'}}
                                                </ng-container>
                                                <ng-template #sqftNA>
                                                    Not Available
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="unit-detail-info">{{unit.madeReadyDate | date:
                                                'MMM d,
                                                y'}}
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="unit-detail-info">{{unit.vacancyDate | date:
                                                'MMM d,
                                                y'}}
                                            </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <div class="amenity-div">
                                                <div *ngFor="let amenity of unit.buildingPropertyAmenities"
                                                    [matTooltip]="amenity.description" class="amenity-item">
                                                    {{amenity.type}} : {{amenity.description}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="unit-detail-info">
                                                <mat-icon *ngIf="unit.description" class="unit-desc"
                                                    matTooltipClass="multiline-tooltip"
                                                    (click)="openUnitDescriptionDialog(unit.description)"
                                                    [matTooltip]="unit.description">info</mat-icon>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">

                                            <ng-container *ngIf="(hasAccessToBooking() | async)">
                                                <div class="reserve-button-div">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                                        aria-label="Inventory actions">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item disabled>
                                                            <mat-icon>lock</mat-icon>
                                                            <span>Reserve</span>
                                                        </button>
                                                        <button mat-menu-item
                                                            (click)="openBookAppointmentDialog(unit)">
                                                            <mat-icon>event_available</mat-icon>
                                                            <span>Book</span>
                                                        </button>
                                                        <button (click)="startNewApplication(unit)" mat-menu-item>
                                                            <mat-icon>description</mat-icon>
                                                            <span>Start Application</span>
                                                        </button>
                                                        <button mat-menu-item [disabled]="!unit.buildingPublicUrl"
                                                            [cdkCopyToClipboard]="unit.buildingPublicUrl"
                                                            (cdkCopyToClipboardCopied)="copiedElement = unit.buildingPublicUrl"
                                                            [matTooltip]="copiedElement === unit.buildingPublicUrl? 'Copied!' : 'Copy'">
                                                            <mat-icon>share</mat-icon>
                                                            <span>Building Link</span>
                                                        </button>
                                                        <button mat-menu-item [disabled]="!unit.unitPublicUrl"
                                                            [cdkCopyToClipboard]="unit.unitPublicUrl"
                                                            (cdkCopyToClipboardCopied)="copiedElement = unit.unitPublicUrl"
                                                            [matTooltip]="copiedElement === unit.unitPublicUrl? 'Copied!' : 'Copy'">
                                                            <mat-icon>share</mat-icon>
                                                            <span>Unit Link</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </ng-container>

                                        </div>

                                        <!-- <div class="col-sm-2">
                                            <div class="reserve-button-div">
                                                <ng-container *ngIf="unit.canRelease">
                                                    <button class="reserve-button release-button"
                                                        mat-stroked-button (click)="openConfirmDialog(unit)">
                                                        <span class="d-xl-inline">
                                                            Release
                                                        </span>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngIf="!(unit.isReserved)">
                                                    <button class="reserve-button"
                                                        mat-stroked-button (click)="openConfirmDialog(unit)">
                                                        <span class="d-xl-inline">
                                                            Reserve
                                                        </span>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngIf="unit.reservedBy && unit.canRelease">
                                                    <span class="reserved-by-span">
                                                        By {{unit.reservedBy}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(units$ | async).length > 0">
                        <div class="units-pagination-div">
                            <pagination-controls class="units-pagination" (pageChange)="unitsPageChanged($event)">
                            </pagination-controls>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(units$ | async).length == 0">
                        <div class="no-units">
                            No Unit
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #unitsLoading>
                    <div class="spinner-container">
                        <mat-spinner></mat-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
export class BuildingPropertyInventoryFloorPlanInventoryRel {
    floorPlanId: number
    buildingPropertyInventoryId: number;
}

export class BuildingPropertyInventoryFloorPlan {
    id: number
    buildingPropertyInventoryId: number;
    floorPlanUri: string;
    fileType: string;
    friendlyName: string;
    unitType: string;
    description: string;
}

export interface IBuildingMessage {
    id: number;
    buildingId: number;
    name: string;
    isActive: boolean;
    text: string;
    startDateTime: string;
    endDateTime: string;
}

export interface IBuildingRentalFormTemplate {
    id: number;
    friendlyName: string;
    type: string;
    version: number;
    versionLanguage: string;
    isSerialNumberRequired: boolean;
}

export interface IBuildingFormTemplateRelsCont {
    formTemplates: IBuildingRentalFormTemplate[];
    buildingFormTemplates: IBuildingRentalFormTemplateRel[];
}

export interface RentalRequestFormTemplateTypes {
    value: string;
    label: string;
}

export interface IBuildingUnit {
    id: number;
    unitIdentifier: string;
    unitType: string;
    unitBedrooms: number;
    unitBathrooms: number;
    unitSquareFeet: number;
    unitPrice: number;
    depositAmount: number;
    isFurnished: boolean;
    madeReadyDate: Date;
    vacancyDate: Date;
    occupencyStatus: string;
    leasedStatus: string;
    unitEconomicStatus: string;
    externalPaltformId: string;
    buildingPropertyId: number;
    propertyName?: string;
}

export interface IBuildingRentalFormTemplateRel {
    rentalFormTemplateId: number;
    isCreationDefault: boolean;
    defaultParameters?: string;
    isTenant?: boolean;
    isGuarantor?: boolean;
    isOccupant?: boolean;
}

export interface IBuildingPropertyRentalItem {
    id: number;
    friendlyName: string;
    type: string;
    price: number;
    isAvailable?: boolean;
    reservedUntilDate?: string;
    externalIdentifier: string;
    buildingPropertyId: string;
    propertyName?: string;
}

export interface IBuildingPropertyRentalItemToAdd {
    friendlyName: string;
    type: string;
    price: number;
    isAvailable?: boolean;
    reservedUntilDate?: string;
    externalIdentifier: string;
    buildingPropertyId: string;
    propertyName?: string;
}

export interface IBuildingUnitToAdd {
    buildingPropertyId: number;
    unitIdentifier: string;
    unitType: string;
    unitBedrooms: number;
    unitBathrooms: number;
    unitSquareFeet: number;
    unitPrice: number;
    depositAmount: number;
    isFurnished: boolean;
    madeReadyDate: Date;
    vacancyDate: Date;
    occupencyStatus: string;
    leasedStatus: string;
    unitEconomicStatus: string;
    externalPaltformId: string;
}

export interface IBuilding {
    id: number;
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    timeZone: string;
    postalCode: string;
    email: string;
    phoneNumber: string;
    apiPhoneNumber: string;
    automatedTextMessageGroupId: number;
    photos?: string[];
    photoBytes: string;
    photoType: string;
    activationDate?: string;
    photo?: string;
    templateIds: number[];
}

export interface IAutomatedTextMessageGroupOption {
    id: number;
    name: string;
}

export interface IBuildingProperty {
    id: number;
    name: string;
    streetAddress: string;
    timeZone: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
    type: string;
    numberOfUnits: number;
    externalPlatformId: string;
}

export interface IBuildingPropertyQueryFilter {
    propertyName: string;
}

export interface IBuildingPropertyToAdd {
    buildingId: number;
    name: string;
    streetAddress: string;
    timeZone: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
    type: string;
    numberOfUnits: number;
    externalPlatformId: string;
}

export interface IBuildingCommunicationSetting {
    textReminderTemplateId: number;
    textReminderDelayInMin: number;
    textReminderVirtualTemplateId: number;
    textReminderVirtualDelayInMin: number;
    emailReminderTemplateId: number;
    emailReminderDelayInMin: number;
    emailReminderVirtualTemplateId: number;
    emailReminderVirtualDelayInMin: number;
    leadIntegrationAutmatedTextMessageTemplateId: number;
    buildingAppointmentEmailTemplateId: number;
    leadIntegrationAutmatedEmailTemplateId: number;
    calendarInviteEmailCcs: string;
    noticeOfEntryTextTemplateId: number;
    noticeOfEntryTextDelayMin: number;
    noticeOfEntryEmailTemplateId: number;
    noticeOfEntryEmailDelayMin: number;
    notifyNewRentalApplication: boolean;
    notifyDepositPayment: boolean;
}

export interface IBuildingLeadIntegrationProviderMappings {
    leadIntegrationProviderMappings: IBuildingLeadIntegrationProviderMapping[];
    isEdited?: boolean;
    isMappingsLoaded?: boolean;
}

export interface IBuildingLeadIntegrationProviderMapping {
    id?: number;
    providerBuildingIdentifier: string;
    buildingId: number;
    leadIntegrationProviderId: string;
}

export interface IBuildingLeadIntegrationProviders {
    leadIntegrationProviders: IBuildingLeadIntegrationProvider[];
    isProvidersLoaded?: boolean;
}

export interface IBuildingLeadIntegrationProvider {
    id: number;
    friendlyName: string;
    description: string;
    providerIdentifier: string;
    isSystemDefault: boolean;
    isActive: boolean;
}

export interface IBuildingLeadIntegrationProviderData {
    id: number;
    dataId: number;
    accountId: number;
}

export interface IBuildingLeasingSetting {
    isFixDepositAmount: boolean;
    defaultDepositAmount: number;
    depositTransactionType: string;
    isFeeRequired: boolean;
    feeAmount: number;
    noticeOfEntryEmailTemplateId?: number
    noticeOfEntryTextTemplateId?: number
    notificationDaysInAdvance?: number
    isNoticeOfEntryActivate?: boolean
}

export interface IBuildingCommunicationSettings {
    isLoading: boolean;
    isEditMode: boolean;
    settings: IBuildingCommunicationSetting;
}

export interface IBuildingCommunicationSettings {
    isLoading: boolean;
    isEditMode: boolean;
    settings: IBuildingCommunicationSetting;
}

export const buildingBaseCost: number = 750

export interface IUtilityPayment {
    id: string
    value: string
}

export const UTILITY_PAYMENT_OPTIONS: IUtilityPayment[] = [
    {
        id: 'landlord',
        value: 'Landlord'
    },
    {
        id: 'tenant',
        value: 'Tenant'
    },
    {
        id: 'landlord and tenant',
        value: 'Landlord and Tenant'
    },
]

export interface ICreateUpdateBuildingRequestParams {
    id?: number;
    name: string
    streetAddress: string
    timeZone: string
    state: string
    city: string
    country: string
    email: string
    apiPhoneNumber: string
    automatedTextMessageGroupId: number
    phoneNumber: string
    postalCode: string
    photoBytes: string
    photoType: string
}

export interface IBuildingInitialForm {
    id?: number;
    name: string
    streetAddress: string
    timeZone: string
    state: string
    city: string
    country: string
    email: string
    phoneNumber: string
    apiPhoneNumber: string
    automatedTextMessageGroupId: number
    postalCode: string
    photoBytes: string
    photoType: string
}

export interface IUnitChoiceOptions {
    value: any;
    viewValue: string;
}

export interface IPropertyRentalItemTypeOptions {
    value: any;
    label: string;
}

export const RENTAL_ITEM_TYPES: IPropertyRentalItemTypeOptions[] = [
    { value: 'Locker', label: 'Locker' },
    { value: 'Shed', label: 'Shed' },
    { value: 'OutdoorParking', label: 'Outdoor Parking' },
    { value: 'IndoorParking', label: 'Indoor Parking' },
    { value: 'Other', label: 'Other' },
    { value: 'Unknown', label: 'Unknown' }
]

export interface IAutomatedTextMessageTemplate {
    id: number;
    name: string;
    accountId: number;
    type: string;
}

export interface IAutomatedEmailTemplate {
    id: number;
    name: string;
    accountId: number;
    type: string;
}

export interface ICommunicationTemplate {
    emailTemplates: IAutomatedEmailTemplate[];
    textMessageTemplates: IAutomatedTextMessageTemplate[];
}

export interface IBuildingCommunicationTemplate {
    automatedEmailTemplates: IAutomatedEmailTemplate[];
    automatedTextMessageTemplates: IAutomatedTextMessageTemplate[];
    isLoading: boolean;
}

export interface BuildingPropertyListRequestParams {
    buildingId: number[];
    propertyName: string;
    page: number;
    pageSize: number;
}

export interface IBuildingPropertyUnitQueryFilterBase {
    unitIdentifier: string;
    buildingId: number;
}

export interface IBuildingPropertyRentalItemQueryFilter {
    baseFilters: IBuildingPropertyRentalItemQueryFilterBase;
    advancedFilters: IBuildingPropertyRentalItemFilterAdvanced;
}

export interface IBuildingPropertyRentalItemQueryFilterBase {
    propertyName: string;
    buildingId: number;
}

export interface IBuildingPropertyRentalItemFilterAdvanced {
    buildingPropertyId?: number;
    isAvailable?: boolean;
    type?: string[];
}

export interface IBuildingPropertyUnitFilterAdvanced {
    buildingPropertyId?: number;
    isMadeReady?: boolean;
    madeReadyStartDate?: string;
    madeReadyEndDate?: string;
    vacancyStartDate?: string;
    vacancyEndDate?: string;
    buildingPropertyIds?: number[];
}
export interface IBuildingPropertyUnitQueryFilter {
    baseFilters: IBuildingPropertyUnitQueryFilterBase;
    advancedFilters: IBuildingPropertyUnitFilterAdvanced;
}
export interface BuildingPropertyUnitListRequestParams {
    buildingPropertyId?: number;
    buildingIds: number[];
    isMadeReady?: boolean;
    madeReadyStartDate?: string;
    madeReadyEndDate?: string;
    vacancyStartDate?: string;
    vacancyEndDate?: string;
    propertyName?: string;
    page: number;
    pageSize: number;
    buildingPropertyIds: number[];
    unitIdentifier?: string;
}

export interface BuildingPropertyRentalItemListRequestParams {
    buildingPropertyId: number;
    buildingId: number[];
    isAvailable?: boolean;
    propertyName: string;
    page: number;
    pageSize: number;
    type: string[];
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS = {
    EMPLOYED: 'Employed',
    SELF_EMPLOYED: 'SelfEmployed',
    RETIRED: 'Retired',
    UNEMPLOYED: 'Unemployed',
    STUDENT: 'Student',
    INTERNATIONAL_STUDENT: 'InternationalStudent',
    UNKNOWN: 'Unknown'
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES = {
    TENANT: 'Tenant',
    OCCUPANT: 'Occupant',
    GUARANTOR: 'Guarantor'
}

export interface BuildingApplicationEmploymentStatus {
    name: string
    value: string
}

export interface BuildingApplicationEmploymentStatusApplicationType {
    name: string
    value: string
}

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_OPTIONS: BuildingApplicationEmploymentStatus[] = [
    {
        name: 'Employed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.EMPLOYED
    },
    {
        name: 'Self Employed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.SELF_EMPLOYED
    },
    {
        name: 'Retired',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.RETIRED
    },
    {
        name: 'Unemployed',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.UNEMPLOYED
    },
    {
        name: 'Student',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.STUDENT
    },
    {
        name: 'International Student',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.INTERNATIONAL_STUDENT
    },
    {
        name: 'Unknown',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS.UNKNOWN
    }
]

export const BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPE_OPTIONS: BuildingApplicationEmploymentStatusApplicationType[] = [
    {
        name: 'Tenant',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.TENANT
    },
    {
        name: 'Occupant',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.OCCUPANT
    },
    {
        name: 'Guarantor',
        value: BUILDING_APPLICATION_EMPLOYMENT_STATUS_APPLICATION_TYPES.GUARANTOR
    },
]

export interface BuildingApplicationEmploymentCheckStatus {
    id: number | string
    buildingId: number
    employementStatus: string
    isAssetCheckRequired: boolean
    isIncomeCheckRequired: boolean
    isCriminalCheckRequired: boolean
    isCertnIdentityCheckRequired: boolean
    isPlaidIdentityCheckRequired: boolean
    isSoftCheckRequired: boolean
    isCreditCheckRequired: boolean
    applicationType: string
}

export class AmenityLight {
    id: number
    name: string
    checked?: boolean


    constructor(id: number, name: string, checked?: boolean) {
        this.id = id;
        this.name = name;
        this.checked = false;
    }
}

export interface CommunityKnowledgeBase {
    id: string
    communityId?: number
    friendlyName: string
    email: string
    phoneNumber: string
    leasingOfficeAddress: string
    description: string
    nearByAreaDescription: string
    hasWaitingList: boolean
    tourAdditionalInformation: string
    tourMinimumNotice: string
    moveInEligibilityWindowInDay: number
    tourMinimumNoticeInHour: number

}

export interface PropertyKnowledgeBase {
    id: string;
    propertyId?: number;
    communityId: number;
    friendlyName: string;
    address: string;
    coordinate: PropertyKnowledgeBaseCoordinate;
    totalUnits: number;
    managementCompany: string;
    owner: string;
    constructionYear?: number;
    timeZone: string;
    buildingType: string;
    propertyClass: string;
    totalFloors?: number;
    hasLobby?: boolean;
    hasSecurityAgent?: boolean;
    hasParkingForVisitor?: boolean;
    hasIndoorParkingForRent?: boolean;
    hasOutdoorParkingForRent?: boolean;
    hasIndoorPool?: boolean;
    hasGym?: boolean;
    hasRooftopAccess?: boolean;
    hasConciergeService?: boolean;
    allowsPets?: boolean;
    hasLaundryFacilities?: boolean;
    hasBicycleStorage?: boolean;
    hasLocker?: boolean;
    hasCinema?: boolean;
    hasLaundryRoom?: boolean;
    hasElectricityIncluded?: boolean;
    hasHeatingIncluded?: boolean;
    hasPetReliefArea?: boolean;
    buildingCalibre: string;
    accessibilityFeatures: string[];
    heatingType: string;
    coolingType: string;
    waterHeatingType: string;
    energyEfficiencyRating: string;
    hasSmartHomeTechnology?: boolean;
    petPolicy: string;
    smokingPolicy: string;
    noisePolicy: string;
    visitorPolicy: string;
    sublettingPolicy: string;
    parkingPolicy: string;
    gymUsagePolicy: string;
    laundryRoomPolicy: string;
    rooftopAccessPolicy: string;
    petDepositPolicy: string;
    airbnbPolicy: string;
    storagePolicy: string;
    depositPolicy: string;
    rentalLeasingFeePolicy: string;
    barbecuePolicy: string;
    lockerPolicy: string;
    leaseTransferPolicy: string;
}

export interface PropertyKnowledgeBaseTemplate {
    id: number;
    friendlyName: string;
    address: string;
    coordinate: PropertyKnowledgeBaseCoordinate;
    totalUnits: number;
    managementCompany: string;
    owner: string;
    constructionYear?: number;
    timeZone: string;
    buildingType: string;
    propertyClass: string;
    totalFloors?: number;
    hasLobby?: boolean;
    hasSecurityAgent?: boolean;
    hasParkingForVisitor?: boolean;
    hasIndoorParkingForRent?: boolean;
    hasOutdoorParkingForRent?: boolean;
    hasIndoorPool?: boolean;
    hasGym?: boolean;
    hasRooftopAccess?: boolean;
    hasConciergeService?: boolean;
    allowsPets?: boolean;
    hasLaundryFacilities?: boolean;
    hasBicycleStorage?: boolean;
    hasLocker?: boolean;
    hasCinema?: boolean;
    hasLaundryRoom?: boolean;
    hasElectricityIncluded?: boolean;
    hasHeatingIncluded?: boolean;
    hasPetReliefArea?: boolean;
    buildingCalibre: string;
    accessibilityFeatures: string[];
    heatingType: string;
    coolingType: string;
    waterHeatingType: string;
    energyEfficiencyRating: string;
    hasSmartHomeTechnology?: boolean;
    petPolicy: string;
    smokingPolicy: string;
    noisePolicy: string;
    visitorPolicy: string;
    sublettingPolicy: string;
    parkingPolicy: string;
    gymUsagePolicy: string;
    laundryRoomPolicy: string;
    rooftopAccessPolicy: string;
    petDepositPolicy: string;
    airbnbPolicy: string;
    storagePolicy: string;
    depositPolicy: string;
    rentalLeasingFeePolicy: string;
    barbecuePolicy: string;
    lockerPolicy: string;
    leaseTransferPolicy: string;
}

export interface PropertyKnowledgeBaseCoordinate {
    latitude: number;
    longitude: number;
}

export interface InventoryAiKnowledgeBase {
    id: string;
    friendlyName: string;
    propertyId: number;
    unitIdentifier: string;
    unitType: string;
    floor?: number;
    squareFootage?: number;
    squareMeter?: number;
    numberOfBedrooms: string;
    numberOfBathrooms: string;
    numberOfBedroomsFriendlyName: string;
    numberOfBathroomsFriendlyName: string;
    unitCondition: string;
    unitOrientation: string;
    isFurnished?: boolean;
    rentalTerm: string;
    hasInUnitLaundry?: boolean;
    hasBalcony?: boolean;
    hasWalkInCloset?: boolean;
    hasAirConditioning?: boolean;
    hasFireplace?: boolean;
    floorCoverings: string;
    viewType: string;
    appliances: string[];
    unitTerminologies: string[];
}

export interface InventoryAiKnowledgeBaseTemplate {
    id: number;
    friendlyName: string;
    unitType: string;
    floor?: number;
    squareFootage?: number;
    squareMeter?: number;
    numberOfBedrooms: string;
    numberOfBathrooms: string;
    numberOfBedroomsFriendlyName: string;
    numberOfBathroomsFriendlyName: string;
    unitCondition: string;
    unitOrientation: string;
    isFurnished?: boolean;
    rentalTerm: string;
    hasInUnitLaundry?: boolean;
    hasBalcony?: boolean;
    hasWalkInCloset?: boolean;
    hasAirConditioning?: boolean;
    hasFireplace?: boolean;
    floorCoverings: string;
    viewType: string;
    appliances: string[];
    unitTerminologies: string[];
}

export const HEATING_TYPE = [
    'Central',
    'Individual Units',
    'Radiators'
]

export const COOLING_TYPE = [
    'Central Air',
    ' Window Units',
]

export const WATER_HEATING_TYPE = [
    'Gas',
    'Electric',
]

export const ENERGY_EFFICIENCY_RATING = [
    'LEED Certified',
    'Energy Star',
]

export const ACCESSIBILITY_FEATURES = [
    'Elevators',
    'Ramps',
    'Accessible Entrances'
]

export const PROPERTY_CLASS_OPTIONS = [
    'Class A',
    'Class B',
    'Class C',
]

export const BUILDING_TYPE_OPTIONS = [
    'Town House',
    'High-rise',
    'Mid-rise',
    'Low-rise',
    'Condo'
]

export const BEDROOM_OPTIONS = [
    {
        label: 'Studio',
        value: 0
    },
    {
        label: '1 Bed',
        value: 1
    },
    {
        label: '1 Bed + Den',
        value: 1.1
    },
    {
        label: '2 Beds',
        value: 2
    },
    {
        label: '2 Beds + Den',
        value: 2.1
    },
    {
        label: '3 Beds',
        value: 3
    },
    {
        label: '3 Beds + Den',
        value: 3.1
    }

]

export const BATHROOM_OPTIONS = [
    {
        label: '1 Bath',
        value: 1
    },
    {
        label: '1 Bath + Half-bath',
        value: 1.5
    }, {
        label: '2 Baths',
        value: 2
    },
    {
        label: '2 Baths + Half-bath',
        value: 2.5
    },
    {
        label: '3 Baths',
        value: 3
    }
]

export const UNIT_CONDITION_OPTIONS = [
    'New',
    'Recently Renovated',
    'Needs Maintenance',
    'Good Condition',
]

export const UNIT_FLOORING_OPTIONS = [
    'Carpet',
    'Hardwood',
    'Tile',
    'Laminate',
    'Vinyl',
]

export const UNIT_ORIENTATION_OPTIONS = [
    'North-facing',
    'South-facing',
    'East-facing',
    'West-facing',
    'Corner Unit',
]

export const UNIT_VIEW_OPTIONS = [
    'City View',
    'Park View',
    'Ocean View',
    'Mountain View',
    'Garden View',
    'Street View',
    'Courtyard View',
]

export const RENTAL_TERM_OPTIONS = [
    '12 months',
    '6 Months',
    '24 Months',
    'Month-to-month',
]

export const UNIT_APPLIANCE_OPTIONS = [
    'Fridge',
    'Stove',
    'Dishwasher',
    'Microwave',
    'Washer',
    'Dryer',
    'Oven',
    'Air Conditioner',
]

export const UNIT_TERMINOGIES_OPTIONS = [
    '3 et demi',
    '3 ½',
    '3 1/2',
    '3 bd'
]

export const UNIT_TYPE_OPTIONS = [
    'Residential',
    'Studio',
    'Retail',
    'Office',
    'Space',
    'Loft',
    'Townhouse'
]
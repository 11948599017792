import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './core/core.module';
import { SignInGuardService } from './core/auth/sign-in-guard.service';
import { RoleGuardService } from './core/auth/role-guard.service';
import { SESSION_USER_ROLES } from './core/auth/auth.models';
import { LandingComponent } from './features/landing/landing.component';
import { PrivacyPolicyComponent } from './features/static/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './features/static/terms-of-use/terms-of-use.component';
import { SupportComponent } from './features/static/support/support.component';
import { ZoomDocumentationComponent } from './features/static/zoom-documentation/zoom-documentation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./features/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate: [SignInGuardService]
  },
  {
    path: 'index',
    component: LandingComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesAppointmentManager, SESSION_USER_ROLES.SalesAppointmentUser]
    }
  },
  {
    path: 'amenity',
    loadChildren: () =>
      import('./features/amenity/amenity.module').then(
        m => m.DqAmenityModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager]
    }
  },
  {
    path: 'building',
    loadChildren: () =>
      import('./features/building/building.module').then(
        m => m.DqBuildingModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.BuildingManager]
    }
  },
  {
    path: 'user-account',
    loadChildren: () =>
      import('./features/user-account/user-account.module').then(
        m => m.UserAccountModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserManager]
    }
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./features/inventory/inventory.module').then(
        m => m.InventoryModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesInventoryUser]
    }
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./features/task/task.module').then(
        m => m.TaskModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.TaskManager, SESSION_USER_ROLES.TaskUser]
    }
  },
  {
    path: 'user-profile',
    loadChildren: () =>
      import('./features/user-profile/user-profile.module').then(
        m => m.UserProfileModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User]
    }
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/account-settings/account-settings.module').then(
        m => m.AccountSettingsModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.User]
    }
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./features/booking/booking.module').then(
        m => m.BookingModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesBookingManager]
    }
  },
  {
    path: 'representative-scheduler',
    loadChildren: () =>
      import('./features/representative-scheduler/representative-scheduler.module').then(
        m => m.RepresentativeSchedulerModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.Representative, SESSION_USER_ROLES.SalesScheduleManager]
    }
  },
  {
    path: 'user-schedule',
    loadChildren: () =>
      import('./features/user-schedule/user-schedule.module').then(
        m => m.UserScheduleModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesScheduleManager]
    }
  },
  {
    path: 'dashboard-user',
    loadChildren: () =>
      import('./features/dashboard-user/dashboard-user.module').then(
        m => m.DashboardUserModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.UserLiveManager]
    }
  },
  {
    path: 'lead-manager',
    loadChildren: () =>
      import('./features/lead-manager/lead-manager.module').then(
        m => m.LeadManagerModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser]
    }
  },
  {
    path: 'lead-queue',
    loadChildren: () =>
      import('./features/lead-queue/lead-queue.module').then(
        m => m.LeadQueueModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser]
    }
  },
  {
    path: 'tenant-manager',
    loadChildren: () =>
      import('./features/tenant-manager/tenant-manager.module').then(
        m => m.TenantManagerModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesLeadManager, SESSION_USER_ROLES.SalesLeadUser]
    }
  },
  {
    path: 'service-request-manager',
    loadChildren: () =>
      import('./features/service-request-manager/service-request-manager.module').then(
        m => m.ServiceRequestManagerModule

      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ServiceReceptionist]
    }
  },
  {
    path: 'com-temaplte',
    loadChildren: () =>
      import('./features/com-template/com-template.module').then(
        m => m.ComTemplateModule

      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.AutoComTemplateManager]
    }
  },
  {
    path: 'automated-delayed-task',
    loadChildren: () =>
      import('./features/automated-delayed-task/automated-delayed-task.module').then(
        m => m.AutomatedDelayedTaskModule

      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin]
    }
  },
  {
    path: 'unified-inbox',
    loadChildren: () =>
      import('./features/unified-inbox/unified-inbox.module').then(
        m => m.UnifiedInboxModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesUnifiedInboxManager]
    }
  },
  {
    path: 'tenant-unified-inbox',
    loadChildren: () =>
      import('./features/tenant-unified-inbox/tenant-unified-inbox.module').then(
        m => m.TenantUnifiedInboxModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.SalesUnifiedInboxManager]
    },
  },
  {
    path: 'anonymous-com',
    loadChildren: () =>
      import('./features/anonymous-com/anonymous-com.module').then(
        m => m.AnonymousComModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.User]
    }
  },
  {
    path: 'rental-form-templates',
    loadChildren: () =>
      import('./features/rental-form-templates/rental-form-templates.module').then(
        m => m.RentalFormTemplatesModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin]
    }
  },
  {
    path: 'account-integration',
    loadChildren: () =>
      import('./features/account-integration/account-integration.module').then(m => m.AccountIntegrationModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'lead-appointment',
    loadChildren: () =>
      import('./features/lead-appointment/lead-appointment.module').then(
        m => m.LeadAppointmentModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin]
    }
  },
  {
    path: 'merchant-account',
    loadChildren: () =>
      import('./features/merchant-account/merchant-account.module').then(
        m => m.MerchantAccountModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.MerchantAccountManager]
    }
  },
  {
    path: 'ai-simulation',
    loadChildren: () =>
      import('./features/ai-simulation/ai-simulation.module').then(
        m => m.AiSimulationModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin]
    }
  },
  {
    path: 'approval-queue',
    loadChildren: () =>
      import('./features/approval-queue/approval-queue.module').then(
        m => m.ApprovalQueueModule
      ),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [SESSION_USER_ROLES.Admin, SESSION_USER_ROLES.ApplicationApprovalsManager, SESSION_USER_ROLES.LeaseSignatory, SESSION_USER_ROLES.ApplicationCreator]
    }
  },
  {
    path: 'terms-of-use',
    // canActivate: [AuthGuardService],
    component: TermsOfUseComponent,
  },
  {
    path: 'privacy-policy',
    // canActivate: [AuthGuardService],
    component: PrivacyPolicyComponent,
  },
  {
    path: 'support',
    // canActivate: [AuthGuardService],
    component: SupportComponent,
  },
  {
    path: 'zoomdocumentation',
    // canActivate: [AuthGuardService],
    component: ZoomDocumentationComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'ignore',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

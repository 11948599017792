import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebApiResponse } from 'app/core/response/response.model';
import { IPagination } from 'app/shared/models/pagination.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { InventoryQeurytFilter, InventoryQueryParams } from './client-dialog-inventory.model';

@Injectable()
export class ClientDialogInventoryService {

  constructor(
    private http: HttpClient
  ) {

  }

  getInventoryBuildings(buildingId: number) {
    return this.http.get(`${environment.apiBaseUrl}dp-inventory/GetAggregatedInventory?buildingId=${buildingId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getInventoryBuildingOptions() {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/GetBuildings`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getYardiInventory(queryFilter: InventoryQeurytFilter, pagination: IPagination) {
    let params: InventoryQueryParams = {
      isMadeReady: queryFilter.baseFilters.isMadeReady,
      isNotReady: queryFilter.baseFilters.isNotReady,
      isReserved: queryFilter.baseFilters.isReserved,
      unitIdentifier: queryFilter.unitIdentifier,
      minPrice: queryFilter.baseFilters.minPrice,
      maxPrice: queryFilter.baseFilters.maxPrice,
      beds: queryFilter.baseFilters.beds,
      baths: queryFilter.baseFilters.bath,
      vacancyStartDate: queryFilter.baseFilters.vacancyStartDate,
      vacancyEndDate: queryFilter.baseFilters.vacancyEndDate,
      madeReadyStartDate: queryFilter.baseFilters.madeReadyStartDate,
      madeReadyEndDate: queryFilter.baseFilters.madeReadyEndDate,
      occupencyStatuses: queryFilter.moreFilters.occupencyStatuses,
      leasedStatuses: queryFilter.moreFilters.leasedStatuses,
      economicStatuses: queryFilter.moreFilters.economicStatuses,
      page: pagination.page,
      pageSize: pagination.pageSize,
      yardiBuildingPropertyIds: queryFilter.baseFilters.buildingPropertyIds,
      yardiBuildingIds: queryFilter.baseFilters.buildingIds
    }
    return this.http.post(`${environment.apiBaseUrl}lead-detail/GetYardiInventory`, params)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  postBuildingPropertiesByBuildingId(buildingIds: number[]) {
    return this.http.post(`${environment.apiBaseUrl}dp-inventory/GetBuildingPropertiesByBuildingId`, buildingIds)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getInventoryUnitsByBuildingId(propertyId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/GetYardiInventoryByBuildingProperty?buildingPropertyId=${propertyId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  reserveUnit(buildingPropertyInventoryId: number, yardiBuildingPropertyId: number, yardiUnitIdentifier: string) {
    return this.http.post(`${environment.apiBaseUrl}dp-inventory/ReserveInventory`,
      {
        buildingPropertyInventoryId: buildingPropertyInventoryId,
        yardiBuildingPropertyId: yardiBuildingPropertyId,
        yardiUnitIdentifier: yardiUnitIdentifier
      })
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  releaseUnit(buildingPropertyInventoryId: number, yardiBuildingPropertyId: number, yardiUnitIdentifier: string) {
    return this.http.post(`${environment.apiBaseUrl}dp-inventory/ReleaseInventory`,
      {
        buildingPropertyInventoryId: buildingPropertyInventoryId,
        yardiBuildingPropertyId: yardiBuildingPropertyId,
        yardiUnitIdentifier: yardiUnitIdentifier
      })
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

}
